import React from "react";
import isEmpty from "lodash/isEmpty";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import "./HTMLToText.css";
import styles from "./HTMLToText.module.scss";

interface HTMLtoTextProps {
  htmlContent: string;
  alternateMessage?: string;
}

const HTMLtoText: React.FC<HTMLtoTextProps> = ({
  htmlContent,
  alternateMessage = "",
}) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);

  // Function to handle the <a> tags and ensure they open in a new tab
  const transformLink = (node: any) => {
    if (node.type === "tag" && node.name === "a") {
      node.attribs.target = "_blank"; 
      node.attribs.rel = "noopener noreferrer";
    }
    return node;
  };

  const parser = new DOMParser();
  const doc = parser.parseFromString(sanitizedHtml, "text/html");
  const stringData = doc.body.textContent || "";
  const reactElement: any = parse(sanitizedHtml, { replace: transformLink });

  return (
    <div className={styles.content_wrapper}>
      {isEmpty(stringData) ? alternateMessage : reactElement}
    </div>
  );
};

export default HTMLtoText;
